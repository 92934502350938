<template>
  <div id="all-components">
    <h2>Buttons</h2>
    <el-row>
      <el-button>Default</el-button>
      <el-button type="primary">Primary</el-button>
      <el-button type="success">Success</el-button>
      <el-button type="info">Info</el-button>
      <el-button type="warning">Warning</el-button>
      <el-button type="danger">Danger</el-button>
    </el-row>
    <h2>Radio Button</h2>
    <el-row>
      <el-radio v-model="radio1" label="1" border>Option A</el-radio>
      <el-radio v-model="radio1" label="2" border>Option B</el-radio>
      <el-radio v-model="radio1" label="3" disabled border>Option C</el-radio>
    </el-row>

    <h2>İnputs</h2>
    <el-row :gutter="20">
      <el-col :span="4">
        <el-input placeholder="Email" type="email" v-model="input"></el-input>
      </el-col>
      <el-col :span="4">
        <el-input placeholder="Email" type="email" v-model="input" disabled></el-input>
      </el-col>
    </el-row>
    <h3>İnput Sizes</h3>
    <el-row :gutter="20">
      <el-col :span="4">
        <el-input placeholder="Please Input" v-model="input1"> </el-input>
      </el-col>
      <el-col :span="4">
        <el-input size="medium" placeholder="Please Input" v-model="input2"> </el-input>
      </el-col>
      <el-col :span="4">
        <el-input size="small" placeholder="Please Input" v-model="input3"> </el-input>
      </el-col>
      <el-col :span="4">
        <el-input size="mini" placeholder="Please Input" v-model="input4"> </el-input>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      radio1: 1,
      input: null,
    };
  },
};
</script>

<style lang="less">
#all-components {
  padding: 30px !important;
}
</style>
